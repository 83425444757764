import React, {useRef, useState, useEffect} from 'react';
import {loadModules} from 'esri-loader';
import Slider from '@material-ui/core/Slider';
import './App.css';
import Logo from "./assets/geosapiens_logo_petit.png";
import {buildingsUrl, comingSoonUrl, waterUrls, yearMarkers} from "./constants/Constants";

export const App = (props) => {
  const mapContainer = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);

  const EmitSliderValue = (value) => {
    // this.setState({value: value});
    const waterLevelEvent = new CustomEvent("waterLevel", {detail: value});
    document.dispatchEvent(waterLevelEvent);
  };

  useEffect(() => {
    // console.log("loading ", props);
    // lazy load the required ArcGIS API for JavaScript modules and CSS
    loadModules(
      [
        // "esri/config",
        "esri/Map",
        "esri/views/MapView",
        "esri/layers/FeatureLayer",
        "esri/layers/TileLayer",
        "esri/widgets/BasemapToggle",
        "esri/widgets/Search"
      ],
      {css: true}
    ).then(
      ([
         // esriConfig,
         Map,
         MapView,
         FeatureLayer,
         TileLayer,
         BasemapToggle,
         Search
       ]) => {
        // esriConfig.apiKey = "AAPK0...";

        const buildingStatus = (feature) => {
          let div = document.createElement("div");
          div.className = "enun-popup";
          div.id = "building-popup";
          let rec = feature.graphic.attributes.rec;
          if (rec === 9999) {
            rec = " > 1 500"
          }
          let niv_risque = feature.graphic.attributes.niv_risque;
          let prob_10 = feature.graphic.attributes.prob_10;
          let prob_20 = feature.graphic.attributes.prob_20;
          let prob_30 = feature.graphic.attributes.prob_30;

          let pEau_2 = feature.graphic.attributes.pEau_2;
          let nbSac_2 = feature.graphic.attributes.nbSac_2;
          let hDigue_2 = feature.graphic.attributes.hDigue_2.toFixed(1);
          let lDigue_2 = feature.graphic.attributes.lDigue_2.toFixed(1);

          let pEau_5 = feature.graphic.attributes.pEau_5;
          let nbSac_5 = feature.graphic.attributes.nbSac_5;
          let hDigue_5 = feature.graphic.attributes.hDigue_5.toFixed(1);
          let lDigue_5 = feature.graphic.attributes.lDigue_5.toFixed(1);

          let pEau_10 = feature.graphic.attributes.pEau_10;
          let nbSac_10 = feature.graphic.attributes.nbSac_10;
          let hDigue_10 = feature.graphic.attributes.hDigue_10.toFixed(1);
          let lDigue_10 = feature.graphic.attributes.lDigue_10.toFixed(1);

          let pEau_20 = feature.graphic.attributes.pEau_20;
          let nbSac_20 = feature.graphic.attributes.nbSac_20;
          let hDigue_20 = feature.graphic.attributes.hDigue_20.toFixed(1);
          let lDigue_20 = feature.graphic.attributes.lDigue_20.toFixed(1);

          let pEau_50 = feature.graphic.attributes.pEau_50;
          let nbSac_50 = feature.graphic.attributes.nbSac_50;
          let hDigue_50 = feature.graphic.attributes.hDigue_50.toFixed(1);
          let lDigue_50 = feature.graphic.attributes.lDigue_50.toFixed(1);

          let pEau_75 = feature.graphic.attributes.pEau_75;
          let nbSac_75 = feature.graphic.attributes.nbSac_75;
          let hDigue_75 = feature.graphic.attributes.hDigue_75.toFixed(1);
          let lDigue_75 = feature.graphic.attributes.lDigue_75.toFixed(1);

          let pEau_100 = feature.graphic.attributes.pEau_100;
          let nbSac_100 = feature.graphic.attributes.nbSac_100;
          let hDigue_100 = feature.graphic.attributes.hDigue_100.toFixed(1);
          let lDigue_100 = feature.graphic.attributes.lDigue_100.toFixed(1);

          let pEau_200 = feature.graphic.attributes.pEau_200;
          let nbSac_200 = feature.graphic.attributes.nbSac_200;
          let hDigue_200 = feature.graphic.attributes.hDigue_200.toFixed(1);
          let lDigue_200 = feature.graphic.attributes.lDigue_200.toFixed(1);

          let tooltipTxt;
          tooltipTxt = "<li>Récurrence : " + rec + " ans</li>" +
            "<li>Niveau de risque : " + niv_risque + "</li>" +
            "<li>Probabilité d'inondation dans les 10 prochaines années: " + prob_10 + "%" +
            "<li>Probabilité d'inondation dans les 20 prochaines années: " + prob_20 + "%" +
            "<li>Probabilité d'inondation dans les 30 prochaines années: " + prob_30 + "%" +
            "<table class='styled-table'><tr><th>Scénario</th><th>Prof. eau</th><th>Nb. sacs</th><th>H. digue</th><th>L. digue</th></tr>" +
            "<tr><td>2 ans</td><td>" + pEau_2 + "</td><td>" + nbSac_2 + "</td><td>" + hDigue_2 + "</td><td>" + lDigue_2 + "</td></tr>" +
            "<tr><td>5 ans</td><td>" + pEau_5 + "</td><td>" + nbSac_5 + "</td><td>" + hDigue_5 + "</td><td>" + lDigue_5 + "</td></tr>" +
            "<tr><td>10 ans</td><td>" + pEau_10 + "</td><td>" + nbSac_10 + "</td><td>" + hDigue_10 + "</td><td>" + lDigue_10 + "</td></tr>" +
            "<tr><td>20 ans</td><td>" + pEau_20 + "</td><td>" + nbSac_20 + "</td><td>" + hDigue_20 + "</td><td>" + lDigue_20 + "</td></tr>" +
            "<tr><td>50 ans</td><td>" + pEau_50 + "</td><td>" + nbSac_50 + "</td><td>" + hDigue_50 + "</td><td>" + lDigue_50 + "</td></tr>" +
            "<tr><td>75 ans</td><td>" + pEau_75 + "</td><td>" + nbSac_75 + "</td><td>" + hDigue_75 + "</td><td>" + lDigue_75 + "</td></tr>" +
            "<tr><td>100 ans</td><td>" + pEau_100 + "</td><td>" + nbSac_100 + "</td><td>" + hDigue_100 + "</td><td>" + lDigue_100 + "</td></tr>" +
            "<tr><td>200 ans</td><td>" + pEau_200 + "</td><td>" + nbSac_200 + "</td><td>" + hDigue_200 + "</td><td>" + lDigue_200 + "</td></tr></table>";

          div.innerHTML = tooltipTxt;
          return (
            div
          )
        };

        const buildingPopup = {
          title: "{Adresse_im}, {Ville}",
          outFields: ["*"],
          content: buildingStatus
        };

        const buildings = new FeatureLayer({
          url: buildingsUrl,
          popupTemplate: buildingPopup,
          renderer: {
            type: "unique-value",
            field: "niv_risque",
            uniqueValueInfos: [
              {
                value: "très faible",
                label: "très faible",
                symbol: {
                  type: "simple-fill",
                  color: "green",
                  outline: {color: "green", width: 1}
                }
              },
              {
                value: "faible",
                label: "faible",
                symbol: {
                  type: "simple-fill",
                  color: "yellow",
                  outline: {color: "yellow", width: 1}
                },
              }, {
                value: "moyen",
                label: "moyen",
                symbol: {
                  type: "simple-fill",
                  color: "orange",
                  outline: {color: "orange", width: 1}
                },
              }, {
                value: "élevé",
                label: "élevé",
                symbol: {
                  type: "simple-fill",
                  color: "red",
                  outline: {color: "red", width: 1}
                },
              }, {
                value: "très élevé",
                label: "très élevé",
                symbol: {
                  type: "simple-fill",
                  color: [130, 16, 16],
                  outline: {color: [130, 16, 16], width: 1}
                },
              }],
          }
        });

        const labelClass = {
          symbol: {
            type: "text",
            color: "black",
            font: {
              family: "Playfair Display",
              size: 14,
              weight: "bold"
            }
          },
          labelPlacement: "center",
          labelExpressionInfo: {
            expression: "$feature.Zone"
          }
        };

        const comingSoon = new FeatureLayer({
          url: comingSoonUrl,
          // id: "building",
          // title: "État des bâtiments",
          // // popupTemplate: buildingPopup,
          // renderer: {
          //   type: "simple",
          //   symbol: {
          //     type: "simple-line",
          //     color: "red",
          //     width: 5
          //   },
          renderer: {
            type: "unique-value",
            field: "Zone",
            uniqueValueInfos: [
              {
                value: "Q3 - 2022",
                symbol: {
                  type: "simple-fill",
                  color: [22, 122, 9, 0.6]
                  // outline: {color: "green", width: 1}
                }
              }, {
                value: "Q4 - 2022",
                symbol: {
                  type: "simple-fill",
                  color: [241, 244, 44, 0.6]
                }
              }, {
                value: "2023",
                symbol: {
                  type: "simple-fill",
                  color: [244, 181, 44, 0.6]
                }
              }
            ],
          },
          labelingInfo: labelClass
          ,
          // minScale: 20000,
          maxScale: 4000000,
          visible: true,
        });

        const scenarios2 = waterUrls[2].map(
          (waterUrl) =>
            new TileLayer({
              url: waterUrl,
              id: "water",
              title: "Hauteur d'eau",
              opacity: 0.9,
              visible: true,
              resampling: true,
              maxScale: 100
            })
        );
        const scenarios5 = waterUrls[5].map(
          (waterUrl) =>
            new TileLayer({
              url: waterUrl,
              id: "water",
              title: "Hauteur d'eau",
              opacity: 0.9,
              visible: true,
              resampling: true,
              maxScale: 100
            })
        );
        const scenarios10 = waterUrls[10].map(
          (waterUrl) =>
            new TileLayer({
              url: waterUrl,
              id: "water",
              title: "Hauteur d'eau",
              opacity: 0.9,
              visible: true,
              resampling: true,
              maxScale: 100
            })
        );
        const scenarios20 = waterUrls[20].map(
          (waterUrl) =>
            new TileLayer({
              url: waterUrl,
              id: "water",
              title: "Hauteur d'eau",
              opacity: 0.9,
              visible: true,
              resampling: true,
              maxScale: 100
            })
        );
        const scenarios50 = waterUrls[50].map(
          (waterUrl) =>
            new TileLayer({
              url: waterUrl,
              id: "water",
              title: "Hauteur d'eau",
              opacity: 0.9,
              visible: true,
              resampling: true,
              maxScale: 100
            })
        );
        const scenarios75 = waterUrls[75].map(
          (waterUrl) =>
            new TileLayer({
              url: waterUrl,
              id: "water",
              title: "Hauteur d'eau",
              opacity: 0.9,
              visible: true,
              resampling: true,
              maxScale: 100
            })
        );
        const scenarios100 = waterUrls[100].map(
          (waterUrl) =>
            new TileLayer({
              url: waterUrl,
              id: "water",
              title: "Hauteur d'eau",
              opacity: 0.9,
              visible: true,
              resampling: true,
              maxScale: 100
            })
        );
        const scenarios200 = waterUrls[200].map(
          (waterUrl) =>
            new TileLayer({
              url: waterUrl,
              id: "water",
              title: "Hauteur d'eau",
              opacity: 0.9,
              visible: true,
              resampling: true,
              maxScale: 100
            })
        );
        const scenarios500 = waterUrls[500].map(
          (waterUrl) =>
            new TileLayer({
              url: waterUrl,
              id: "water",
              title: "Hauteur d'eau",
              opacity: 0.9,
              visible: true,
              resampling: true,
              maxScale: 100
            })
        );
        const scenarios1500 = waterUrls[1500].map(
          (waterUrl) =>
            new TileLayer({
              url: waterUrl,
              id: "water",
              title: "Hauteur d'eau",
              opacity: 0.9,
              visible: true,
              resampling: true,
              maxScale: 100
            })
        );
        const scenarioAll = [scenarios2, scenarios5, scenarios10, scenarios20, scenarios50, scenarios75, scenarios100, scenarios200, scenarios500, scenarios1500];
        const map = new Map({
          basemap: "gray",
          // basemap: "dark-gray",
          // basemap: "dark-gray-vector",
          // basemap: "streets-night-vector",
          // basemap: "hybrid",
          // basemap: "satellite",
          layers: scenarioAll[0]
        });
        //Set view
        const view = new MapView({
          container: mapContainer.current,
          map: map,
          center: [-72.470999, 46.307752],
          // center: [-73.990657, 45.391444],
          // sliderPosition: "top-left",
          zoom: 8,
          // zoom: 15,
          // constraints: {
          //   maxZoom: 19,
          //   minZoom: 8,
          //   geometry: {
          //     type: "extent",
          //     xmin: -77.65,
          //     ymin: 45.00,
          //     xmax: -69.86,
          //     ymax: 48.00
          //   },
          // },
        });
        map.add(buildings);
        map.add(comingSoon);

        //Set basemap toggle
        const basemapToggle = new BasemapToggle({
          view: view,
          // nextBasemap: "streets-navigation-vector",
          nextBasemap: "satellite",
        });

        const search = new Search({
          view: view
        });

        view.ui.add(basemapToggle, "bottom-right");
        view.ui.add(search, "top-right");

        const changeScenario = (id) => {
          // console.log(id);
          map.removeAll();
          map.addMany(scenarioAll[id]);
          map.add(buildings);
          map.add(comingSoon);
        };
        document.addEventListener("waterLevel", (event) => {
          changeScenario(event.detail);
        });

        setMapInstance(view);
      }
    );
    return () => {
      setMapInstance(null)
    }
  }, [mapContainer]);

  return (
    <>
      <div className={"menu"}>
        <img className="logo" src={Logo} alt="logo"/>
        <Slider
          className={"slider"}
          min={0}
          max={9}
          // value={this.state.value}
          // valueLabelFormat={`${this.props.labels[this.state.value]} ${uniteMesure}`}
          // valueLabelDisplay="on"
          marks={yearMarkers}
          onChange={(_, value) => EmitSliderValue(value)}
        />
      </div>
      <div className={"map"} ref={mapContainer}/>
    </>
  );
};


