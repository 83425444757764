// export const buildingsUrl = "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/_Merge_BD/FeatureServer";
export const buildingsUrl = "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Batiments_BD_report/FeatureServer";
export const comingSoonUrl = "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/dev_zone_simple/FeatureServer";

export const waterUrls = {
  2: [
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DeuxMontagnes_PI_rec0002_2345_tif/MapServer", //DM
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Plaine_Inondable_0210m3s_tif/MapServer", //AS 210
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S1_1/MapServer", //OU S1
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S2_1/MapServer", //OU S2
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Gatineau_1/MapServer", //GA
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DesMilesIles_Plaine_Inondable_0002Ans_tif/MapServer", //MI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Prairies_1/MapServer", //PR
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Plaine_Inondable_05375m3s_tif/MapServer", //RI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chateauguay_1/MapServer", //CHATEAUGUAY
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StLouis_1/MapServer", //StL
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StFrancois_1/MapServer", //StF
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Richelieu_Plaine_Inondable_00985m3s_tif/MapServer", //Richelieu
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ChaudiereNord_Plaine_Inondable_01250m3s_tif/MapServer", //CD Nord python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_StLambert_PI_1/MapServer", //CD Nord
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Plaine_Inondable_00750m3s_tif/MapServer" //CD Sud python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Centre_PI_1/MapServer", //CD Centre
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Sud_PI_1/MapServer", //CD Sud
    // "", //QC old (non dispo)
  ],
  5: [
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DeuxMontagnes_PI_rec0005_2370_tif/MapServer", //DM
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Plaine_Inondable_0255m3s_tif/MapServer", //AS 255
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S1_2/MapServer", //OU S1
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S2_2/MapServer", //OU S2
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Gatineau_2/MapServer", //GA
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DesMilesIles_Plaine_Inondable_0005Ans_tif/MapServer", //MI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Prairies_2/MapServer", //PR
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Plaine_Inondable_06125m3s_tif/MapServer", //RI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chateauguay_2/MapServer", //CHATEAUGUAY
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StLouis_2/MapServer", //StL
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StFrancois_2/MapServer", //StF
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Richelieu_Plaine_Inondable_01075m3s_tif/MapServer", //Richelieu
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ChaudiereNord_Plaine_Inondable_01500m3s_tif/MapServer", //CD Nord python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_StLambert_PI_2/MapServer", //CD Nord
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Plaine_Inondable_00950m3s_tif/MapServer", //CD Sud python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Centre_PI_2/MapServer", //CD Centre
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Sud_PI_2/MapServer", //CD Sud
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Quebec_old_2/MapServer", //QC old
  ],
  10: [
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DeuxMontagnes_PI_rec0010_2385_tif/MapServer", //DM
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Plaine_Inondable_0290m3s_tif/MapServer", //AS 290
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S1_3/MapServer", //OU S1
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S2_3/MapServer", //OU S2
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Gatineau_3/MapServer", //GA
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DesMilesIles_Plaine_Inondable_0010Ans_tif/MapServer", //MI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Prairies_3/MapServer", //PR
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Plaine_Inondable_06750m3s_tif/MapServer", //RI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chateauguay_3/MapServer", //CHATEAUGUAY
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StLouis_3/MapServer", //StL
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StFrancois_3/MapServer", //StF
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Richelieu_Plaine_Inondable_01150m3s_tif/MapServer", //Richelieu
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ChaudiereNord_Plaine_Inondable_01750m3s_tif/MapServer", //CD Nord python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_StLambert_PI_3/MapServer", //CD Nord
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Plaine_Inondable_01100m3s_tif/MapServer", //CD Sud python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Centre_PI_3/MapServer", //CD Centre
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Sud_PI_3/MapServer", //CD Sud
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Quebec_old_3/MapServer", //QC old
  ],
  20: [
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DeuxMontagnes_PI_rec0020_2405_tif/MapServer", //DM
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Plaine_Inondable_0325m3s_tif/MapServer", //AS 325
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S1_4/MapServer", //OU S1
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S2_4/MapServer", //OU S2
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Gatineau_4/MapServer", //GA
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DesMilesIles_Plaine_Inondable_0020Ans_tif/MapServer", //MI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Prairies_4/MapServer", //PR
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Plaine_Inondable_07250m3s_tif/MapServer", //RI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chateauguay_4/MapServer", //CHATEAUGUAY
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StLouis_4/MapServer", //StL
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StFrancois_4/MapServer", //StF
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Richelieu_Plaine_Inondable_01225m3s_tif/MapServer", //Richelieu
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ChaudiereNord_Plaine_Inondable_02000m3s_tif/MapServer", //CD Nord python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_StLambert_PI_4/MapServer", //CD Nord
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Plaine_Inondable_01350m3s_tif/MapServer", //CD Sud python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Centre_PI_4/MapServer", //CD Centre
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Sud_PI_4/MapServer", //CD Sud
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Quebec_old_4/MapServer", //QC old
  ],
  50: [
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DeuxMontagnes_PI_rec0050_2430_tif/MapServer", //DM
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Plaine_Inondable_0365m3s_tif/MapServer", //AS 365
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S1_5/MapServer", //OU S1
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S2_5/MapServer", //OU S2
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Gatineau_5/MapServer", //GA
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DesMilesIles_Plaine_Inondable_0050Ans_tif/MapServer", //MI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Prairies_5/MapServer", //PR
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Plaine_Inondable_08000m3s_tif/MapServer", //RI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chateauguay_5/MapServer", //CHATEAUGUAY
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StLouis_5/MapServer", //StL
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StFrancois_5/MapServer", //StF
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Richelieu_Plaine_Inondable_01330m3s_tif/MapServer", //Richelieu
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ChaudiereNord_Plaine_Inondable_02450m3s_tif/MapServer", //CD Nord python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_StLambert_PI_5/MapServer", //CD Nord
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Plaine_Inondable_01650m3s_tif/MapServer", //CD Sud python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Centre_PI_5/MapServer", //CD Centre
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Sud_PI_5/MapServer", //CD Sud
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Quebec_old_5/MapServer", //QC old
  ],
  75: [
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DeuxMontagnes_PI_rec0075_2440_tif/MapServer", //DM
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Plaine_Inondable_0385m3s_tif/MapServer", //AS 385
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S1_6/MapServer", //OU S1
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S2_6/MapServer", //OU S2
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Gatineau_6/MapServer", //GA
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DesMilesIles_Plaine_Inondable_0075Ans_tif/MapServer", //MI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Prairies_6/MapServer", //PR
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Plaine_Inondable_08375m3s_tif/MapServer", //RI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chateauguay_6/MapServer", //CHATEAUGUAY
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StLouis_6/MapServer", //StL
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StFrancois_6/MapServer", //StF
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Richelieu_Plaine_Inondable_01375m3s_tif/MapServer", //Richelieu
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ChaudiereNord_Plaine_Inondable_02650m3s_tif/MapServer", //CD Nord python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_StLambert_PI_6/MapServer", //CD Nord
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Plaine_Inondable_01850m3s_tif/MapServer", //CD Sud python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Centre_PI_6/MapServer", //CD Centre
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Sud_PI_6/MapServer", //CD Sud
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Quebec_old_6/MapServer", //QC old
  ],
  100: [
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DeuxMontagnes_PI_rec0100_2450_tif/MapServer", //DM
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Plaine_Inondable_0400m3s_tif/MapServer", //AS 400
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S1_7/MapServer", //OU S1
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S2_7/MapServer", //OU S2
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Gatineau_7/MapServer", //GA
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DesMilesIles_Plaine_Inondable_0100Ans_tif/MapServer", //MI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Prairies_7/MapServer", //PR
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Plaine_Inondable_08625m3s_tif/MapServer", //RI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chateauguay_7/MapServer", //CHATEAUGUAY
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StLouis_7/MapServer", //StL
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StFrancois_7/MapServer", //StF
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Richelieu_Plaine_Inondable_01405m3s_tif/MapServer", //Richelieu
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ChaudiereNord_Plaine_Inondable_02800m3s_tif/MapServer", //CD Nord python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_StLambert_PI_7/MapServer", //CD Nord
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Plaine_Inondable_01950m3s_tif/MapServer", //CD Sud python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Centre_PI_7/MapServer", //CD Centre
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Sud_PI_7/MapServer", //CD Sud
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Quebec_old_7/MapServer", //QC old
  ],
  200: [
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DeuxMontagnes_PI_rec0200_2465_tif/MapServer", //DM
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Plaine_Inondable_0435m3s_tif/MapServer", //AS 435
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S1_8/MapServer", //OU S1
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S2_8/MapServer", //OU S2
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Gatineau_8/MapServer", //GA
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DesMilesIles_Plaine_Inondable_0200Ans_tif/MapServer", //MI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Prairies_8/MapServer", //PR
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Plaine_Inondable_09250m3s_tif/MapServer", //RI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chateauguay_8/MapServer", //CHATEAUGUAY
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StLouis_8/MapServer", //StL
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StFrancois_8/MapServer", //StF
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Richelieu_Plaine_Inondable_01480m3s_tif/MapServer", //Richelieu
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ChaudiereNord_Plaine_Inondable_03250m3s_tif/MapServer", //CD Nord python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_StLambert_PI_8/MapServer", //CD Nord
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Plaine_Inondable_02350m3s_tif/MapServer", //CD Sud python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Centre_PI_8/MapServer", //CD Centre
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Sud_PI_8/MapServer", //CD Sud
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Quebec_old_8/MapServer", //QC old
  ],
  500: [
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DeuxMontagnes_PI_rec0500_2490_tif/MapServer", //DM
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Plaine_Inondable_0470m3s_tif/MapServer", //AS 470
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S1_9/MapServer", //OU S1
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S2_9/MapServer", //OU S2
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Gatineau_9/MapServer", //GA
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DesMilesIles_Plaine_Inondable_01825m3s_tif/MapServer", //MI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Prairies_9/MapServer", //PR
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Plaine_Inondable_10625m3s_tif/MapServer", //RI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chateauguay_9/MapServer", //CHATEAUGUAY
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StLouis_9/MapServer", //StL
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StFrancois_9/MapServer", //StF
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Richelieu_Plaine_Inondable_01660m3s_tif/MapServer", //Richelieu
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ChaudiereNord_Plaine_Inondable_03950m3s_tif/MapServer", //CD Nord python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_StLambert_PI_9/MapServer", //CD Nord
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Plaine_Inondable_02950m3s_tif/MapServer", //CD Sud python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Centre_PI_9/MapServer", //CD Centre
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Sud_PI_9/MapServer", //CD Sud
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Quebec_old_9/MapServer", //QC old
  ],
  1500: [
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DeuxMontagnes_PI_rec1500_2520_tif/MapServer", //DM
    "", //AS (565 non dispo)
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S1_10/MapServer", //OU S1
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S2_10/MapServer", //OU S2
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Gatineau_10/MapServer", //GA
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DesMilesIles_Plaine_Inondable_02150m3s_tif/MapServer", //MI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Prairies_10/MapServer", //PR
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Plaine_Inondable_12250m3s_tif/MapServer", //RI
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chateauguay_10/MapServer", //CHATEAUGUAY
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StLouis_10/MapServer", //StL
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/StFrancois_10/MapServer", //StF
    "", //Richelieu (non dispo)
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ChaudiereNord_Plaine_Inondable_04950m3s_tif/MapServer", //CD Nord python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_StLambert_PI_10/MapServer", //CD Nord
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Plaine_Inondable_03850m3s_tif/MapServer", //CD Sud python
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Centre_PI_10/MapServer", //CD Centre
    // "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Sud_PI_10/MapServer", //CD Sud
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Quebec_old_10/MapServer", //QC old
  ]
};

//     "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S1_10/MapServer", //OU S1
//     "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Outaouais_S2_10/MapServer", //OU S2
//     "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_StLambert_PI_10/MapServer", //CD Nord
//     "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Centre_PI_10/MapServer", //CD Centre
//     "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Sud_PI_10/MapServer", //CD Sud

export const yearMarkers = [
  {
    value: 0,
    label: '2',
  },
  {
    value: 1,
    label: '5',
  },
  {
    value: 2,
    label: '10',
  },
  {
    value: 3,
    label: '20',
  },
  {
    value: 4,
    label: '50',
  },
  {
    value: 5,
    label: '75',
  },
  {
    value: 6,
    label: '100',
  },
  {
    value: 7,
    label: '200',
  },
  {
    value: 8,
    label: '500',
  },
  {
    value: 9,
    label: '1500',
  }
];